/* eslint-disable */
require('script-loader!file-saver');
const { blob } = require('./Blob');
// require('script-loader!./Blob');
require('script-loader!xlsx/dist/xlsx.core.min');
const XLSXS= require('xlsx-js-style')
function generateArray(table) {
    var out = [];
    var rows = table.querySelectorAll('tr');
    var ranges = [];
    for (var R = 0; R < rows.length; ++R) {
        var outRow = [];
        var row = rows[R];
        var columns = row.querySelectorAll('td');
        for (var C = 0; C < columns.length; ++C) {
            var cell = columns[C];
            var colspan = cell.getAttribute('colspan');
            var rowspan = cell.getAttribute('rowspan');
            var cellValue = cell.innerText;
            if (cellValue !== "" && cellValue == +cellValue) cellValue = +cellValue;

            //Skip ranges
            ranges.forEach(function (range) {
                if (R >= range.s.r && R <= range.e.r && outRow.length >= range.s.c && outRow.length <= range.e.c) {
                    for (var i = 0; i <= range.e.c - range.s.c; ++i) outRow.push(null);
                }
            });

            //Handle Row Span
            if (rowspan || colspan) {
                rowspan = rowspan || 1;
                colspan = colspan || 1;
                ranges.push({ s: { r: R, c: outRow.length }, e: { r: R + rowspan - 1, c: outRow.length + colspan - 1 } });
            }
            ;

            //Handle Value
            outRow.push(cellValue !== "" ? cellValue : null);

            //Handle Colspan
            if (colspan) for (var k = 0; k < colspan - 1; ++k) outRow.push(null);
        }
        out.push(outRow);
    }
    return [out, ranges];
};

function datenum(v, date1904) {
    if (date1904) v += 1462;
    var epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function sheet_from_array_of_arrays(data, opts) {
    var ws = {};
    var range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
    for (var R = 0; R != data.length; ++R) {
        for (var C = 0; C != data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            var cell = { v: data[R][C] };
            if (cell.v == null) continue;
            var cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n';
                cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            }
            else cell.t = 's';

            ws[cell_ref] = cell;
        }
    }
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
    return ws;
}

function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook();
    this.SheetNames = [];
    this.Sheets = {};
}

function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

export function export_table_to_excel(id) {
    var theTable = document.getElementById(id);
    console.log('a')
    var oo = generateArray(theTable);
    var ranges = oo[1];

    /* original data */
    var data = oo[0];
    var ws_name = "SheetJS";
    console.log(data);

    var wb = new Workbook(), ws = sheet_from_array_of_arrays(data);

    /* add ranges to worksheet */
    // ws['!cols'] = ['apple', 'banan'];
    ws['!merges'] = ranges;

    /* add worksheet to workbook */
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;

    var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: false, type: 'binary' });

    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), "test.xlsx")
}

function formatJson(jsonData) {
    console.log(jsonData)
}
export function export_json_to_excel(th, jsonData, defaultTitle) {

    /* original data */

    var data = jsonData;
    data.unshift(th);
    var ws_name = "SheetJS";

    var wb = new Workbook(), ws = sheet_from_array_of_arrays(data);

    // 自适应单元格宽度 start================================
    /*设置worksheet每列的最大宽度*/
    const colWidth = data.map(row => row.map(val => {
        /*先判断是否为null/undefined*/
        if (val == null) {
            return { 'wch': 10 };
        }
        /*再判断是否为中文*/
        else if (val.toString().charCodeAt(0) > 255) {
            return { 'wch': val.toString().length * 2 };
        } else {
            return { 'wch': val.toString().length };
        }
    }))
    /*以第一行为初始值*/
    let result = colWidth[0];
    for (let i = 1; i < colWidth.length; i++) {
        for (let j = 0; j < colWidth[i].length; j++) {
            if (result[j]['wch'] < colWidth[i][j]['wch']) {
                result[j]['wch'] = colWidth[i][j]['wch'];
            }
        }
    }
    ws['!cols'] = result;
    // 自适应宽度 end============================

    /* add worksheet to workbook */
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;

    var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: false, type: 'binary' });
    var title = defaultTitle || '列表'
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), title + ".xlsx")
}

/**
 * 常旅商实习审批导出excel
 * @param excelData
 */
export function cls_sxsp_export_to_excel(excelData) {
    let workbook = XLSXS.utils.book_new(); // 工作簿
    let worksheet = XLSXS.utils.aoa_to_sheet(excelData); // 数据表
    //以下是样式设置，样式设置放在组织完数据之后，xlsx-js-style的核心API就是SheetJS的
    //样式的设置可以参考相关文档 贴在文章末尾第二个链接
    //修改列宽cols 行宽就用rows 总共5列
    let cols = 18
    let rows = excelData.length - 1
    worksheet["!cols"] = []
    for (let i = 1; i <= cols; i++) {
        let col = {}
        if ([1,3,5,8].indexOf(i) >= 0) {
            col.wch = 10
        } else if ([9,11].indexOf(i) >= 0) {
            col.wch = 14
        } else if (i === 7) {
          col.wch = 8
        } else {
            col.wch = 15
        }
        worksheet["!cols"].push(col)
    }

    // 修改行高
    worksheet["!rows"] = [
        {
            hpx: 50
        }
    ]
    worksheet["!rows"][rows] = {hpx: 120}
    //合并第一行单元格 s和e指范围即第一行 第一列到第五列
    worksheet["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 17 } },
        {s: {r:2, c: 4}, e: { r:2,c:5}},
        {s: {r:2, c: 9}, e: { r:2,c:11}},
        { s: {r: rows, c:1}, e: {r: rows, c: 4} },
        { s: {r: rows, c:6}, e: {r: rows, c: 9} },
        { s: {r: rows, c:11}, e: {r: rows, c: 13} },
        { s: {r: rows, c:15}, e: {r: rows, c: 17} }
    ];
    for (let i = 3; i < rows; i++) {
        worksheet["!merges"].push({s: {r:i, c:4}, e: {r:i,c:5}},{s: {r:i, c:9}, e: {r:i, c:11}})
    }
    Object.keys(worksheet).forEach((key,i) => {
        // 非!开头的属性都是单元格
        if (!key.startsWith('!')) {
            //设置第一行样式 第一行合并了五个单元格 因此只用设置A1
            if (key == "A1") {
                worksheet[key].s = {
                    font: {
                        name: "宋体",
                        sz: 20,
                        bold: true,
                        color: "000000",
                    },
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true,
                    },
                };
            } else if (Number(key.match(/\d+/)[0]) === 2) {
                    worksheet[key].s = {
                        font: {
                            name: "宋体",
                            sz: 14,
                            bold: false,
                            color: "000000",
                        },
                        alignment: {
                            horizontal: "center",
                            vertical: "center",
                            wrapText: true,
                        },
                        border: {
                            top: { style: 'medium' },
                            right: { style: key.indexOf('R')>=0 ?'medium':'thin' },
                            bottom: { style: 'medium' },
                            left: { style: 'thin' }
                        }
                    }
            } else if (Number(key.match(/\d+/)[0]) === excelData.length) {
                let num = excelData.length
                worksheet[key].s = {
                    font: {
                        name: "宋体",
                        sz: 14,
                        bold: false,
                        color: "000000",
                    },
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true,
                    },
                    border: {
                        top: { style: 'medium' },
                        right: { style: key.indexOf('R')>=0 ?'medium':'thin' },
                        bottom: { style: 'medium' },
                        left: { style: 'thin' }
                    }
                }
                if (key === `B${num}` || key === `G${num}` || key === `L${num}` || key === `P${num}`) {
                    worksheet[key].s.alignment.horizontal = 'left'
                    worksheet[key].s.alignment.vertical = 'bottom'
                }
            } else {
                worksheet[key].s = {
                    font: {
                        name: "宋体",
                        sz: 14,
                        bold: false,
                        color: "000000",
                    },
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true,
                    },
                    border: {
                        top: { style: 'thin' },
                        right: { style: key.indexOf('R')>=0 ? 'medium' : 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' }
                    }
                }
            }
        }
    });
    XLSXS.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSXS.writeFile(workbook, `${excelData[0][0]}.xlsx`);//表格名称
}


/**
 * 靖江中专实习情况登记表导出excel
 * @param excelData
 */
export function jjzz_sxsp_export_to_excel(excelData, exportName) {
    let workbook = XLSXS.utils.book_new(); // 工作簿
    let worksheet = XLSXS.utils.aoa_to_sheet(excelData); // 数据表
    //以下是样式设置，样式设置放在组织完数据之后，xlsx-js-style的核心API就是SheetJS的
    //样式的设置可以参考相关文档 贴在文章末尾第二个链接
    //修改列宽cols 行宽就用rows 总共5列
    let cols = 18
    let rows = excelData.length - 1
    worksheet["!cols"] = []
    for (let i = 1; i <= cols; i++) {
        let col = {}
        if ([1,6].indexOf(i) >= 0) {
            col.wch = 8
        }  else if ([7].indexOf(i) >= 0) {
            col.wch =30
        } else if ([ 9, 12].indexOf(i) >= 0) {
            col.wch = 45
        } else {
            col.wch = 20
        }
        worksheet["!cols"].push(col)
    }

    // 修改行高
    worksheet["!rows"] = [
        {
            hpx: 50
        }
    ]
    worksheet["!rows"][0] = { hpx: 120 }

    // worksheet["!rows"][rows] = { hpx: 120 }//最后一行
    //合并第一行单元格 s和e指范围即第一行 第一列到第五列
    worksheet["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 17 } },
        // { s: { r: 2, c: 4 }, e: { r: 2, c: 5 } },
        // { s: { r: 2, c: 9 }, e: { r: 2, c: 11 } },
        // { s: { r: rows, c: 1 }, e: { r: rows, c: 4 } },
        // { s: { r: rows, c: 6 }, e: { r: rows, c: 9 } },
        // { s: { r: rows, c: 11 }, e: { r: rows, c: 13 } },
        // { s: { r: rows, c: 15 }, e: { r: rows, c: 17 } }
    ];
    // for (let i = 3; i < rows; i++) {
    //     worksheet["!merges"].push({ s: { r: i, c: 4 }, e: { r: i, c: 5 } }, { s: { r: i, c: 9 }, e: { r: i, c: 11 } })
    // }


    const borderAll = {
        top: {
            style: "thin"
        },
        bottom: {
            style: "thin"
        },
        left: {
            style: "thin"
        },
        right: {
            style: "thin"
        }
    }

    Object.keys(worksheet).forEach((key, i) => {
        // 非!开头的属性都是单元格
        if (!key.startsWith('!')) {
            //设置第一行样式 第一行合并了五个单元格 因此只用设置A1
            if (key == "A1") {
                worksheet[key].s = {
                    font: {
                        // name: "宋体",
                        sz: 16,
                        bold: true,
                        color: "000000"
                    },
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true,
                    },
                };
            } else if (Number(key.match(/\d+/)[0]) === 2) {
                worksheet[key].s = {
                    font: {
                        name: "宋体",
                        sz: 14,
                        bold: false,
                        color: "000000",
                    },
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true,
                    },
                    border: borderAll
                    // border: {
                    //     top: { style: 'medium' },
                    //     right: { style: key.indexOf('R') >= 0 ? 'medium' : 'thin' },
                    //     bottom: { style: 'medium' },
                    //     left: { style: 'thin' }
                    // }
                }
            } else if (Number(key.match(/\d+/)[0]) === excelData.length) {
                let num = excelData.length
                worksheet[key].s = {
                    font: {
                        name: "宋体",
                        sz: 14,
                        bold: false,
                        color: "000000",
                    },
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true,
                    },
                    border: borderAll
                    // border: {
                    //     top: { style: 'medium' },
                    //     right: { style: key.indexOf('R') >= 0 ? 'medium' : 'thin' },
                    //     bottom: { style: 'medium' },
                    //     left: { style: 'thin' }
                    // }
                }
                if (key === `B${num}` || key === `G${num}` || key === `L${num}` || key === `P${num}`) {
                    worksheet[key].s.alignment.horizontal = 'left'
                    worksheet[key].s.alignment.vertical = 'bottom'
                }
            } else {
                worksheet[key].s = {
                    font: {
                        name: "宋体",
                        sz: 14,
                        bold: false,
                        color: "000000",
                    },
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true,
                    },
                    border: borderAll
                    // border: {
                    //     top: { style: 'thin' },
                    //     right: { style: key.indexOf('R') >= 0 ? 'medium' : 'thin' },
                    //     bottom: { style: 'thin' },
                    //     left: { style: 'thin' }
                    // }
                }
            }
        }
    });
    XLSXS.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSXS.writeFile(workbook, exportName);//exportName表格名称
}

export function njsyxx_statistics_export_excel(excelData, cols) {
    let workbook = XLSXS.utils.book_new(); // 工作簿
    let worksheet = XLSXS.utils.aoa_to_sheet(excelData); // 数据表
    //以下是样式设置，样式设置放在组织完数据之后，xlsx-js-style的核心API就是SheetJS的
    //样式的设置可以参考相关文档 贴在文章末尾第二个链接
    //修改列宽cols 行宽就用rows 总共5列
    worksheet["!cols"] = []
    for (let i = 1; i <= cols; i++) {
        worksheet["!cols"].push({wch: 30})
    }

    // 修改行高
    worksheet["!rows"] = [
        {
            hpx: 30
        }
    ]
    //合并第一行单元格 s和e指范围即第一行 第一列到第五列
    worksheet["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: cols - 1 } },
    ];
    Object.keys(worksheet).forEach((key,i) => {
        // 非!开头的属性都是单元格
        if (!key.startsWith('!')) {
            //设置第一行样式 第一行合并了五个单元格 因此只用设置A1
            if (key == "A1") {
                worksheet[key].s = {
                    font: {
                        name: "宋体",
                        sz: 20,
                        bold: true,
                        color: "000000",
                    },
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true,
                    },
                };
            } else if (Number(key.match(/\d+/)[0]) === 2) {
                worksheet[key].s = {
                    font: {
                        name: "宋体",
                        sz: 14,
                        bold: true,
                        color: "000000",
                    },
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true,
                    },
                    border: {
                        top: { style: 'thin' },
                        right: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' }
                    }
                }
            } else {
                worksheet[key].s = {
                    font: {
                        name: "宋体",
                        sz: 13,
                        bold: false,
                        color: "000000",
                    },
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true,
                    },
                    border: {
                        top: { style: 'thin' },
                        right: { style: 'thin' },
                        bottom: { style: 'thin' },
                        left: { style: 'thin' }
                    }
                }
            }
        }
    });
    XLSXS.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSXS.writeFile(workbook, `${excelData[0][0]}.xlsx`);//表格名称
}


//设置合并数据
/**
 * 处理excel格子合并缺少边框函数
 */
function addRangeBorder(range, ws) {
    // 动态生成列编号['A', 'B', ..., 'AA', 'AB', ...]
    let cols = returnStrList(getColNum(ws))
    range.forEach(item => {
        let style = {
            s: {
                border: {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                }
            },
            t: "s",
            v: ""
        }
        // 处理合并行
        for (let i = item.s.c; i <= item.e.c; i++) {
            ws[`${cols[i]}${Number(item.e.r) + 1}`] = ws[`${cols[i]}${Number(item.e.r) + 1}`] || style
            // 处理合并列
            for (let k = item.s.r + 2; k <= item.e.r + 1; k++) {
                ws[cols[i] + k] = ws[cols[k] + item.e.r] || style
            }
        }
    })
    return ws;
}

// 获取sheet的最大列数:number
function getColNum(sheet) {
    const col = sheet["!ref"].split(':')[1]
    const a = col.replace(new RegExp(/[0-9]/, "gm"), '')
    const letterList = a.split('')
    let sum = 0
    const ordA = 'A'.charCodeAt(0)
    letterList.forEach((item, index) => {
        sum = (item.charCodeAt(0) - ordA + 1) * (26 ** (letterList.length - 1 - index)) + sum
    })
    return sum
}

// 根据列数生成列号数组：['A', 'B', 'C', ...]
function returnStrList(num) {
    const strList = []
    if (num > 0) {
        for (let i = 0; i < num; i++) {
            strList.push(createCellPos(i))
        }
    }
    return strList
}

// 根据数字生成对应列号: 'A'
function createCellPos(n){
    var ordA = 'A'.charCodeAt(0);
    var ordZ = 'Z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
    var s = "";
    while( n >= 0 ) {
        s = String.fromCharCode(n % len + ordA) + s
        n = Math.floor(n / len) - 1
    }
    return s;
}

export function njxx_sxmc_export(data, filename) {
   let workbook = njxx_sxmc_excel(data)
    XLSXS.writeFile(workbook, `${filename || '毕业生顶岗实习名册'}.xlsx`);//表格名称
}

function njxx_sxmc_excel(data) {
    let workbook = XLSXS.utils.book_new(); // 工作簿
    let worksheet = XLSXS.utils.aoa_to_sheet(data); // 数据表
    //以下是样式设置，样式设置放在组织完数据之后，xlsx-js-style的核心API就是SheetJS的
    //样式的设置可以参考相关文档 贴在文章末尾第二个链接
    //修改列宽cols 行宽就用rows 总共5列
    worksheet["!cols"] = [
        {wch: 8},
        {wch: 15},
        {wch: 6},
        {wch: 8},
        {wch: 40},
        {wch: 20},
        {wch: 20},
        {wch: 15},
        {wch: 15},
        {wch: 12},
        {wch: 22},
    ]
    // 修改行高
    worksheet["!rows"] = [
        {hpx: 33},
        {hpx: 77},
        {hpx: 30},
        {hpx: 28},
        {hpx: 28},
    ]
    for (let i = 0; i < data.length - 5; i++) {
        worksheet["!rows"].push({hpx: 30})
    }
    //合并第一行单元格 s和e指范围即第一行 第一列到第五列
    worksheet["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 10 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 10 } },
        { s: { r: 3, c: 7 }, e: { r: 3, c: 8 } },
    ];
    for (let i = 0; i <= 10; i++) {
        if (i !== 7 && i !== 8) {
            worksheet["!merges"].push({ s: { r: 3, c: i }, e: { r: 4, c: i } })
        }
    }
    Object.keys(worksheet).forEach((key,i) => {
        // 非!开头的属性都是单元格
        if (!key.startsWith('!')) {
            let borderStyle = {
                top: { style: 'thin'},
                right: { style: 'thin'},
                bottom: { style: 'thin' },
                left: { style: 'thin'}
            }
            worksheet[key].s = {
                font: {
                    name: "宋体",
                    sz: 11,
                    bold: false,
                    color: "000000",
                },
                alignment: {
                    horizontal: "center",
                    vertical: "center",
                    wrapText: true,
                },
                border: borderStyle,
            }
            //设置第一行样式 第一行合并了五个单元格 因此只用设置A1
            if (key == "A1") {
                worksheet[key].s.font = {
                    name: "宋体",
                    sz: 22,
                    bold: true,
                    color: "000000",
                }
                worksheet[key].s.alignment = {
                    horizontal: "center",
                    vertical: "center",
                    wrapText: true,
                }
            } else if (key == "A2") {
                worksheet[key].s = {
                    font: {
                        name: "宋体",
                        sz: 12,
                        bold: false,
                        color: {
                            rgb: "FF0000"
                        },
                    },
                    alignment: {
                        horizontal: "left",
                        vertical: "center",
                        wrapText: true,
                    },
                    border: borderStyle,
                };
            } else if (key == "A3") {
                worksheet[key].s = {
                    font: {
                        name: "宋体",
                        sz: 12,
                        bold: false,
                        color: "000000",
                    },
                    alignment: {
                        horizontal: "left",
                        vertical: "center",
                        wrapText: true,
                    },
                    border: borderStyle,
                };
            }
        }
    });
    addRangeBorder(worksheet['!merges'], worksheet)
    XLSXS.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    return workbook
}


export function njxx_sxmc_blob(data) {
    let workbook = njxx_sxmc_excel(data)
    let wbout = XLSXS.write(workbook,{ bookType: 'xlsx', bookSST: false, type: 'binary' })
    return new Blob([s2ab(wbout)], { type: "application/octet-stream" })
}